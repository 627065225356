import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Paper,
  Grid,
  Stack,
  Box,
  Typography,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Modal,
  Divider,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MUIDataTable from "mui-datatables";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { getPostData } from "../redux/action/customer_issue_action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};
const empCollectionRef = collection(db, "ticketData");
const CustomerIssues = () => {
  const dispatch = useDispatch();
  const [ticket, setTicket] = useState("Order"); // Default value
  const [issueid, setIssueid] = useState("");
  const [orderid, setOrderid] = useState("");
  const [accountid, setAccountid] = useState("");
  const [transactionid, setTransactionid] = useState("");
  const [accountidError, setAccountidError] = useState("");
  const [orderidError, setOrderidError] = useState("");
  const [transactionidError, setTransactionidError] = useState("");
  const [reasono, setReasono] = useState("");
  const [reasonoError, setReasonoError] = useState("");
  const [reasont, setReasont] = useState("");
  const [reasontError, setReasontError] = useState("");
  const [reasona, setReasona] = useState("");
  const [reasonaError, setReasonaError] = useState("");
  const [note, setNote] = useState("");
  const [customer, setCustomerId] = useState("");
  const [CustomerIdError, setCustomerIdError] = useState("");
  const [showOtherReasonField, setShowOtherReasonField] = useState(false);
  const [ticketError, setTicketError] = useState("");
  const [reasonErroro, setReasonErroro] = useState("");
  const [reasonErrort, setReasonErrort] = useState("");
  const [reasonErrora, setReasonErrora] = useState("");
  const [otherReasonTexto, setOtherReasonTexto] = useState("");
  const [otherReasonTextt, setOtherReasonTextt] = useState("");
  const [otherReasonTexta, setOtherReasonTexta] = useState("");
  const [noteError, setNoteError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [configuration, setConfiguration] = useState();
  const userdata = useSelector((state) => state.post);
  const [ddata, setDdata] = useState();
  const [ticketData, setOrderData] = useState();
  const [udata, setUdata] = useState([]);

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  // useEffect(() => {
  //   // Create a reference to your Firestore collection
  //   const collectionRef = collection(db, "ticketData");
  //   // Subscribe to real-time updates for the collection
  //   const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
  //     const updatedData = [];
  //     snapshot.forEach((doc) => {
  //       updatedData.push(doc.data());
  //     });
  //     setDdata(updatedData);
  //   });
  //   // Clean up the subscription when the component unmounts
  //   return () => unsubscribe();
  // }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    if (userdata.data.length) {
      setConfiguration(userdata);
    } else {
      console.log("Data is not available");
    }
  }, [userdata]);

  console.log("Order data:", ticketData);
  console.log(ticketData?.data[1]?.ticketId, "345666");

  const handleOpen = (item, row) => {
    for (let i = 0; i < configuration.data.length; i++) {
      if (item[0] === configuration.data[i].ticketId) {
        return setFormattedData([configuration.data[i]]), setOpenDrawer(true);
      }
    }
  };

  const handleChange = (e, i) => {
    setFormattedData((item) => {
      return item?.map((list, index) => {
        if (index === i) {
          return { ...list, [e.target.name]: e.target.value };
        }
        return list;
      });
    });

    // console.log(udata , "!!234");
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const getUsers = async () => {
      try {
        const snapshot = await getDocs(empCollectionRef);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error retrieving users:", error);
        return [];
      }
    };
    getUsers()
      .then((users) => {
        console.log(formattedData, "12233");
        const filteredUsers = users.filter(
          (user) => user.ticketId === formattedData[0].ticketId
        );
        if (filteredUsers.length > 0) {
          const docId = filteredUsers[0].id; // Assuming `id` is the key for the document ID field
          if (!docId) {
            console.error("Document ID is missing or invalid.");
            return;
          }

          const empDocRef = doc(empCollectionRef, docId);

          const updateData = {
            ...formattedData[0], // Use the desired array element or properties here
          };

          if (updateData.ticketStatus === "Closed") {
            // If the ticket is closed, update the closedAt field with the current date
            updateData.closedAt = new Date();
          }

          alert("Succesfully Updated!!!");
          setEditMode(false);
          updateDoc(empDocRef, updateData)
            .then(() => {
              console.log("Data updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating document:", error);
            });
        } else {
          console.log("No matching users found.");
        }
      })
      .catch((error) => console.error(error));
  };

  const pointerStyle = {
    cursor: "pointer",
  };
  const paperStyle = {
    backgroundColor: "#1976D2", // Default color
  };

  const paperHoverStyle = {
    backgroundColor: "#38b6ff", // Hover color
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const validateInput = () => {
    let isValid = true; // Assume the form is valid initially
    // Clear all error messages
    setOrderidError("");
    setTransactionidError("");
    setCustomerIdError("");
    setNoteError("");
    setReasonoError("");
    setReasontError("");
    setReasonaError("");
    setReasonErroro("");
    setReasonErrort("");
    setReasonErrora("");
    setReasonoError("");

    // Validate Ticket Type
    if (!ticket) {
      setTicketError("Please select a ticket type");
      isValid = false;
    }
    // Validate Order Id or Transaction Id based on Ticket Type
    if (ticket === "Order" && !orderid) {
      setOrderidError("Please enter Order id");
      isValid = false;
    }
    if (ticket === "Transaction" && !transactionid) {
      setTransactionidError("Please enter Transaction id");
      isValid = false;
    }
    // Validate Customer Id
    if (!customer) {
      setCustomerIdError("Please enter Customer id");
      isValid = false;
    }
    // Validate Note
    if (!note) {
      setNoteError("Please enter any message");
      isValid = false;
    }
    // Validate Ticket Reasons
    if (!reasono && !reasont && !reasona) {
      setReasonErroro("Please enter reason");
      setReasonErrort("Please enter reason");
      setReasonErrora("Please enter reason");
      isValid = false;
    }
    if (ticket === "Order" && !reasonoError) {
      setReasonoError("Please select any reason");
      isValid = false;
    }
    if (ticket === "Transaction" && !reasontError) {
      setReasontError("Please select any reason");
      isValid = false;
    }
    if (ticket === "Account" && !reasonaError) {
      setReasonaError("Please select any reason");
      isValid = false;
    }
    // Validate Other Reasons (if selected)
    if (reasono === "Other" && !otherReasonTexto) {
      setReasonErroro("Please enter reason");
      isValid = false;
    }
    if (reasont === "Other" && !otherReasonTextt) {
      setReasonErrort("Please enter reason");
      isValid = false;
    }
    if (reasona === "Other" && !otherReasonTexta) {
      setReasonErrora("Please enter reason");
      isValid = false;
    }
    return isValid;
  };
  const handleSave = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    // Validate the form
    const isValid = validateInput();
    if (isValid) {
      // Form is valid, proceed with saving data
      console.log("Form submitted successfully");
      handleClose1();
      setOrderid("");
      setTicket("");
      setTransactionid("");
      setReasono("");
      setReasont("");
      setReasona("");
      setCustomerId("");
      setNote("");
      try {
        // Create a reference to your Firestore collection
        const collectionRef = collection(db, "ticketData");
        // Create a new document data based on the form input

        let ticketReason = reasono || reasont || reasona; // Initialize with the selected value from dropdown

        // If "Other" was selected, update ticketReason with the value from the "Other Reason" text field
        if (reasono === "Other") {
          ticketReason = otherReasonTexto;
        } else if (reasont === "Other") {
          ticketReason = otherReasonTextt;
        } else if (reasona === "Other") {
          ticketReason = otherReasonTexta;
        }

        const newData = {
          adminResponse: "",
          closedAt: "",
          createdAt: new Date(),
          customerSatisfaction: "",
          subjectId: orderid || transactionid || null,
          ticketContent: note,
          ticketId: Math.ceil(Math.random() * 10000000000000),
          ticketReason: ticketReason, // Update ticketReason based on the logic above
          ticketStatus: "Pending",
          ticketType: ticket,
          userId: customer,
          userType: "Customer",
        };
        // Add the new document to the collection
        const docRef = await addDoc(collectionRef, newData);
        console.log("Data added successfully! Document reference:", docRef.id);
        // Reset form fields and errors
        handleClose1();
        setOrderid("");
        setTicket("");
        setTransactionid("");
        setReasono("");
        setReasont("");
        setReasona("");
        setCustomerId("");
        setNote("");
        setOtherReasonTexto(""); // Add similar reset statements for other reason fields
        setOtherReasonTextt("");
        setOtherReasonTexta("");

        // Optionally, you can also update the tableData state to include the new data in the table.
        // Make sure to update tableData based on your requirement.
      } catch (error) {
        console.error("Error adding document:", error);
      }
    } else {
      // Form validation failed
      console.log("Form validation failed");
    }
  };

  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleEditButtonClick = () => {
    setEditMode(true);
  };
  const handleSubmitButtonClick = () => {
    // Update the status of the corresponding row in tableData
    if (editRowIndex !== null && tableData[editRowIndex]) {
      const updatedData = [...tableData];
      updatedData[editRowIndex][10] = selectedStatus; // Set the status to the selected value
      setTableData(updatedData);
    }
    setOpenDrawer(false);
    setEditMode(false);
  };

  const formatTime = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${formattedDate} :: ${hours}:${minutes}:${seconds}`;
    }
    return ""; // Return an empty string if timestamp is null
  };
  const CustomBodyRenderLite = ({ tableMeta }) => {
    const handleRowClick = () => {
      const rowData = tableMeta.rowData;
      return rowData;
    };

    return (
      <>
        <Stack direction="row" spacing={1}>
          <Link>
            <Button variant="contained" size="small" color="primary">
              <EditIcon fontSize="small" />
            </Button>
          </Link>
        </Stack>
      </>
    );
  };

  const columns = [
    {
      name: "ticketId",
      label: "Issue ID",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "createdAt",
      label: "createdAt",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value) => formatTime(value),
      },
    },
    {
      name: "closedAt",
      label: "closedAt",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value) => formatTime(value),
      },
    },

    {
      name: "ticketType",
      label: "ticket Type",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "Contact number",
      label: "Contact number",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "Type",
      label: "Type",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "Reason",
      label: "Reason",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "Note",
      label: "Note",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "userType",
      label: "userType",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "ticketStatus",
      label: "ticket Status",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (rowData, tableMeta, updateValue) =>
          CustomBodyRenderLite({ rowData, tableMeta, updateValue }),
      },
    },
  ];

  const options = {
    filter: false, // Hide the filter bar
    search: true,
    pagination: true,
    selectableRowsHideCheckboxes: true,
    onRowClick: (rowData, row) => handleOpen(rowData, row),
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  return (
    <div>
      <Grid
        item
        sx={{ maxWidth: "20%", marginTop: "30px" }}
        style={pointerStyle}
      >
        <Paper
          onClick={handleOpen1}
          style={
            isHovered2 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
          }
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Box
                borderRadius="50%"
                width="30px"
                height="40px"
                style={{}}
              ></Box>
            </div>
            <div>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "5px",
                }}
              ></Typography>

              <Typography
                style={{
                  color: "black",
                  display: "flex",
                  gap: "8px",
                  marginRight: "25px",
                }}
              >
                {/* <AddCircleOutlineIcon /> */}
                <Box fontSize={12}>Add CustomerIssues</Box>
              </Typography>
            </div>
            <div />
          </div>
        </Paper>
      </Grid>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <form onSubmit={handleSave}>
          <Box sx={style}>
            <Typography variant="h6" align="left">
              Customer Issue
            </Typography>
            <Divider />
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={handleClose1}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ marginTop: "10px", minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Ticket Type
                  </InputLabel>
                  <Select
                    value={ticket}
                    onChange={(e) => {
                      setTicket(e.target.value);
                      setTicketError("");
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label=" Ticket Type"
                  >
                    <MenuItem value="Order">Order</MenuItem>
                    <MenuItem value="Transaction">Transaction</MenuItem>
                    <MenuItem value="Account">Account</MenuItem>
                  </Select>
                </FormControl>
                {/* {isSubmitted && ticketError && (
                  <span style={{ color: "red" }}>{ticketError}</span>
                )} */}
              </Grid>
              <Grid item xs={6}>
                {ticket === "Order" && (
                  <TextField
                    label="Order id"
                    name="order id"
                    value={orderid}
                    onChange={(e) => {
                      const selectedOrderid = e.target.value;
                      setOrderid(selectedOrderid);
                      validateInput(selectedOrderid, "orderid"); // Validate Order Id
                    }}
                    sx={{ marginTop: "10px", width: "267px" }}
                    variant="outlined"
                  />
                )}
                {ticket === "Order" && isSubmitted && orderidError && (
                  <span style={{ color: "red" }}>{orderidError}</span>
                )}

                {ticket === "Transaction" && (
                  <TextField
                    label="Transaction id"
                    name="Transaction id"
                    value={transactionid}
                    onChange={(e) => {
                      const selectedTransactionId = e.target.value;
                      setTransactionid(selectedTransactionId);
                      validateInput(selectedTransactionId, "transactionid"); // Validate Transaction Id
                    }}
                    sx={{ marginTop: "10px", width: "267px" }}
                    variant="outlined"
                  />
                )}
                {ticket === "Transaction" &&
                  isSubmitted &&
                  transactionidError && (
                    <span style={{ color: "red" }}>{transactionidError}</span>
                  )}

                {/* {ticket === "Account" && (
                  <TextField
                    label="Account id"
                    name="Account id"
                    value={accountid}
                    onChange={(e) => {
                      setAccountid(e.target.value);
                      setAccountidError("");
                    }}
                    sx={{ marginTop: "10px", width: "267px" }}
                    variant="outlined"
                  />
                )}
                {isSubmitted && accountidError && (
                  <span style={{ color: "red" }}>{accountidError}</span>
                )} */}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Customer Id"
                  name="Customer Id"
                  value={customer}
                  onChange={(e) => {
                    setCustomerId(e.target.value);
                    setCustomerIdError("");
                  }}
                  sx={{ marginTop: "10px", width: "267px" }}
                  variant="outlined"
                />
                {isSubmitted && CustomerIdError && (
                  <span style={{ color: "red" }}>{CustomerIdError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                {ticket === "Order" && (
                  <FormControl
                    sx={{ marginTop: "10px", minWidth: "100%" }}
                    variant="outlined"
                    m={2}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Ticket Reasons
                    </InputLabel>
                    <Select
                      value={reasono}
                      onChange={(e) => {
                        const selectedReason = e.target.value;
                        setReasono(selectedReason);
                        setReasona("");
                        setReasont("");
                        setReasonoError("");
                        setShowOtherReasonField(selectedReason === "Other");
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Ticket Reason"
                    >
                      <MenuItem value=" Customer not reachable">
                        Customer not reachable
                      </MenuItem>
                      <MenuItem value="Customer cancelling order">
                        Customer cancelling order
                      </MenuItem>
                      <MenuItem value="Customer misbehaving">
                        Customer misbehaving
                      </MenuItem>
                      <MenuItem value="Customer location incorrect">
                        Customer location incorrect
                      </MenuItem>
                      <MenuItem value=" Customer want direct riders">
                        Customer want direct riders
                      </MenuItem>
                      <MenuItem value="Other rider picked up order">
                        Other rider picked up order
                      </MenuItem>
                      <MenuItem value="Client did self delivery">
                        Client did self delivery
                      </MenuItem>
                      <MenuItem value="item not ready">item not ready</MenuItem>
                      <MenuItem value="Client not at pickup point">
                        Client not at pickup point
                      </MenuItem>
                      <MenuItem value="Customer did not pay trip amount">
                        Customer did not pay trip amount
                      </MenuItem>
                      <MenuItem value="Weight issue">Weight issue</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {ticket === "Order" && isSubmitted && reasonoError && (
                  <span style={{ color: "red" }}>{reasonoError}</span>
                )}
                {ticket === "Transaction" && (
                  <FormControl
                    sx={{ marginTop: "10px", minWidth: "100%" }}
                    variant="outlined"
                    m={2}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Ticket Reasons
                    </InputLabel>
                    <Select
                      value={reasont}
                      onChange={(e) => {
                        const selectedReason = e.target.value;
                        setReasont(selectedReason);
                        setReasona("");
                        setReasono("");
                        setReasontError("");
                        setShowOtherReasonField(selectedReason === "Other");
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Ticket Reason"
                    >
                      <MenuItem value="Wallet not showing added money">
                        Wallet not showing added money
                      </MenuItem>
                      <MenuItem value="Wallet showing wrong details">
                        Wallet showing wrong details
                      </MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {ticket === "Transaction" && isSubmitted && reasontError && (
                  <span style={{ color: "red" }}>{reasontError}</span>
                )}
                {ticket === "Account" && (
                  <FormControl
                    sx={{ marginTop: "10px", minWidth: "100%" }}
                    variant="outlined"
                    m={2}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Ticket Reasons
                    </InputLabel>
                    <Select
                      value={reasona}
                      onChange={(e) => {
                        const selectedReason = e.target.value;
                        setReasona(selectedReason);
                        setReasono("");
                        setReasont("");
                        setReasonaError("");
                        setShowOtherReasonField(selectedReason === "Other");
                      }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Ticket Reason"
                    >
                      <MenuItem value="Waiting for Approval">
                        Waiting for Approval
                      </MenuItem>
                      <MenuItem value="Wrong account details showing">
                        Wrong account details showing
                      </MenuItem>
                      <MenuItem value=" Can\'t change account information">
                        {" "}
                        Can\'t change account information
                      </MenuItem>
                      <MenuItem value=" Did not recieve referral amount">
                        Did not recieve referral amount
                      </MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {ticket === "Account" && isSubmitted && reasonaError && (
                  <span style={{ color: "red" }}>{reasonaError}</span>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Other Description"
                  name="Note"
                  multiline
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                    setNoteError("");
                  }}
                  sx={{ marginTop: "10px", width: "267px" }}
                  variant="outlined"
                />
                {isSubmitted && noteError && (
                  <span style={{ color: "red" }}>{noteError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                {reasono === "Other" && (
                  <TextField
                    label="Other Reason"
                    name="other Reason"
                    value={otherReasonTexto}
                    onChange={(e) => {
                      setOtherReasonTexto(e.target.value);
                      setReasonErroro("");
                      setReasonErroro("");
                      // setReasont("");
                      // setReasona("");
                    }}
                    sx={{ marginTop: "10px", width: "267px" }}
                    variant="outlined"
                  />
                )}
                {reasono === "Other" && isSubmitted && reasonErroro && (
                  <span style={{ color: "red" }}>{reasonErroro}</span>
                )}
                {reasont === "Other" && (
                  <TextField
                    label="Other Reason"
                    name="other Reason"
                    value={otherReasonTextt}
                    onChange={(e) => {
                      setOtherReasonTextt(e.target.value);
                      setReasonErrort("");
                      // setReasono("");
                      // setReasona("");
                    }}
                    sx={{ marginTop: "10px", width: "267px" }}
                    variant="outlined"
                  />
                )}
                {reasont === "Other" && isSubmitted && reasonErrort && (
                  <span style={{ color: "red" }}>{reasonErrort}</span>
                )}
                {reasona === "Other" && (
                  <TextField
                    label="Other Reason"
                    name="other Reason"
                    value={otherReasonTexta}
                    onChange={(e) => {
                      setOtherReasonTexta(e.target.value);
                      setReasonErrora("");
                      // setReasont("");
                      // setReasono("");
                    }}
                    sx={{ marginTop: "10px", width: "267px" }}
                    variant="outlined"
                  />
                )}
                {reasona === "Other" && isSubmitted && reasonErrora && (
                  <span style={{ color: "red" }}>{reasonErrora}</span>
                )}
              </Grid>
            </Grid>
            <Button
              sx={{
                display: "flex",
                minWidth: 220,
                margin: "0 auto",
                marginTop: "5%",
              }}
              type="submit"
              ml={10}
              variant="contained"
              size="large"
              color="primary"
              mt={3}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Modal>
      <Grid sx={{ mt: 1 }}>
        <MUIDataTable
          data={ddata ? ddata : configuration?.data}
          columns={columns}
          options={options}
        />
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        {formattedData?.map((item, index) => {
          console.log("Items+++++++++++++++++++++++", item);
          return (
            <>
              <div style={{ padding: 20, width: "985px" }}>
                <Typography variant="h4" style={{ marginBottom: 10 }}>
                  CustomerIssues
                </Typography>

                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    //   maxWidth: "800px",
                    margin: "0 auto",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                >
                  <Typography variant="body1">Customer Issues</Typography>
                  <>
                    <div style={{ marginRight: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {item.ticketType === "Order" && (
                            <TextField
                              disabled={!editMode}
                              label="Subject Id (Order ID)"
                              name="subjectId"
                              value={item.subjectId}
                              onChange={(event) => handleChange(event, index)}
                              variant="outlined"
                              sx={{ mt: 3, minWidth: "100%" }}
                            />
                          )}

                          {item.ticketType === "Transaction" && (
                            <TextField
                              disabled={!editMode}
                              label="Subject Id (Transaction ID)"
                              name="subjectId"
                              value={item.subjectId}
                              onChange={(event) => handleChange(event, index)}
                              variant="outlined"
                              sx={{ mt: 3, minWidth: "100%" }}
                            />
                          )}
                          {item.ticketType === "Account" &&
                            {
                              /* <TextField
                              disabled={!editMode}
                              label="Subject Id ( Acconut ID)"
                              name="subjectId"
                              value={item.subjectId}
                              onChange={(event) => handleChange(event, index)}
                              variant="outlined"
                              sx={{ mt: 3, minWidth: "100%" }}
                            /> */
                            }}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            label="Ticket Id"
                            name="ticketId"
                            value={item.ticketId}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                            sx={{ mt: 3, minWidth: "102%" }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            label="Ticket Type"
                            name="ticketType"
                            value={item.ticketType}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                            sx={{ mt: 3, minWidth: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            label="Customer Id"
                            name="userId"
                            value={item.userId}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                            sx={{ mt: 3, minWidth: "102%" }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            label="User Type"
                            name="userType"
                            value={item.userType}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                            sx={{ mt: 3, minWidth: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            label="Customer Satisfaction"
                            name="customerSatisfaction"
                            value={item.customerSatisfaction}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                            sx={{ mt: 3, minWidth: "102%" }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            sx={{ mt: 1, minWidth: "100%" }}
                            label="ticket Reason"
                            name="ticketReason"
                            multiline
                            value={item.ticketReason}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            sx={{ mt: 1, minWidth: "100%" }}
                            label="Ticket Content"
                            name="ticketContent"
                            multiline
                            value={item.ticketContent}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              mt: 1,
                              minWidth: "102%",
                              marginLeft: "10px",
                            }}
                            variant="outlined"
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Ticket Status
                            </InputLabel>
                            <Select
                              name={"ticketStatus"}
                              disabled={!editMode}
                              value={item.ticketStatus}
                              onChange={(event) => {
                                handleChange(event, index);
                              }}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label=" Ticket Status"
                            >
                              <MenuItem value="Pending">{"Pending"}</MenuItem>
                              <MenuItem value="Closed">{"Closed"}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={!editMode}
                            label="Admin Response"
                            name="adminResponse"
                            value={item.adminResponse}
                            onChange={(event) => handleChange(event, index)}
                            variant="outlined"
                            sx={{ mt: 3, minWidth: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                </Box>
              </div>
              {editMode ? (
                <Button
                  sx={{ m: 3, maxWidth: "70px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  onClick={handleUpdate}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  sx={{ m: 3, maxWidth: "70px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  onClick={handleEditButtonClick}
                >
                  Edits
                </Button>
              )}
            </>
          );
        })}
      </Drawer>
    </div>
  );
};

export default CustomerIssues;
