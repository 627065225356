import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import "../App.css";
import {
  Button,
  Card,
  Chip,
  Drawer,
  Stack,
  TextField,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPostData } from "../redux/action/bulk_action";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const zoomedImageStyle = {
  transition: "transform 0.3s, position 0.3s",
  cursor: "pointer",
  textAlign: "center",
};

const zoomedImageZoomedStyle = {
  transform: "scale(2)",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: "auto",
  zIndex: 1000,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const zoomableImageStyle = {
  // borderRadius: "50%",
  width: "100px",
  height: "100px",
  transition: "transform 0.3s",
};

const pointerStyle = {
  cursor: "pointer",
};

const BulkOrder = () => {
  const dispatch = useDispatch();
  const [zoomed, setZoomed] = useState(false);
  const toggleZoom = () => {
    setZoomed(!zoomed);
  };
  const [zoomed1, setZoomed1] = useState(false);
  const toggleZoom1 = () => {
    setZoomed1(!zoomed1);
  };
  const [zoomed3, setZoomed3] = useState(false);
  const toggleZoom3 = () => {
    setZoomed3(!zoomed3);
  };

  const [zoomStates, setZoomStates] = useState([]);

  const toggleZooms = (index, imageType) => {
    const newZoomStates = [...zoomStates];
    if (!newZoomStates[index]) {
      newZoomStates[index] = {};
    }
    newZoomStates[index][imageType] = !newZoomStates[index][imageType];
    setZoomStates(newZoomStates);
  };

  const userdata = useSelector((state) => state.post);
  const [orderData, setOrderData] = useState();
  const [selectedGrid, setSelectedGrid] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  console.log("------------------", userdata);
  useEffect(() => {
    if (userdata.data.length) {
      setOrderData(userdata);
    } else {
      console.log("Data is not available");
    }
  }, [userdata]); // Add 'userdata' as a dependency

  console.log("Order data:", orderData);
  console.log(orderData?.data[1]?.orderId, "345666");
  const [formattedData, setFormattedData] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [ddata, setDdata] = useState();
  const [rdata, setRdata] = useState();

  const handleOpen = (item, row) => {
    console.log(item, "11111111111!!!");
    setRdata(item);
    for (let i = 0; i < orderData.data.length; i++) {
      console.log("Comparing:", item[1], orderData.data[i].orderId); // Add this log
      if (item[1] === orderData.data[i].orderId) {
        setFormattedData([orderData.data[i]]);
        setOpenDrawer(true);
      }
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };
  const data = ddata ? ddata : orderData?.data;
  const Rows = data?.map((row, index) => ({
    ...row,

    style: { background: "blue" },
  }));

  const handleStartDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();

    if (selectedDate > today) {
      alert("From date cannot be in the future.");
      setStartDate(""); // Clear the input field
    } else {
      setStartDate(event.target.value);
    }
  };

  const handleEndDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();

    if (selectedDate > today) {
      alert("To date cannot be in the future.");
      setEndDate(""); // Clear the input field
    } else {
      setEndDate(event.target.value);
    }
  };

  const filterDataByDate = () => {
    if (startDate && endDate) {
      const today = new Date(); // Get today's date
      const filteredData = orderData?.data.filter((row) => {
        const createdAt = new Date(row.createdAt.seconds * 1000);
        console.log("createdATCHanges", createdAt);
        if (createdAt > today) {
          // If it's in the future, reset the input fields
          setStartDate("");
          setEndDate("");
          return false; // Exclude this row from the filteredData
        }
        return (
          createdAt >= new Date(startDate) && createdAt <= new Date(endDate)
        );
      });
      setDdata(filteredData);
    } else {
      setDdata(orderData?.data);
    }
  };
  const formatTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const handleDateBlur = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();
    if (selectedDate > today) {
      // If the selected date is in the future, reset the input field
      setStartDate("");
    }
  };

  const options = {
    filter: false, // Hide the filter bar
    searchOpen: true,
    search: false,
    selectableRows: "multiple",
    selectableRowsHideCheckboxes: true,
    onRowClick: (rowData, row) => handleOpen(rowData, row),
    setRowProps: (row) => {
      // console.log(row , "@@@@@@00000");
      const orderStatus = row[10];
      let backgroundColor;

      if (orderStatus === "Assigned") {
        backgroundColor = "yellow";
      } else if (orderStatus === "Rejected") {
        backgroundColor = "blue";
      } else if (orderStatus === "PickedUp") {
        backgroundColor = "skyblue";
      } else if (orderStatus === "Delivered") {
        backgroundColor = "green";
      } else if (orderStatus === "Accepted") {
        backgroundColor = "orange";
      } else if (orderStatus === "TripEnded") {
        backgroundColor = "pink";
      } else if (orderStatus === "inTransit") {
        backgroundColor = "voilet";
      } else if (orderStatus === "TripStarted") {
        backgroundColor = "grey";
      } else if (orderStatus === "Arrived") {
        backgroundColor = "light green";
      } else if (orderStatus === "hanged") {
      } else if (orderStatus === "Rejected") {
        backgroundColor = "light green";
      } else if (orderStatus === "Cancelled") {
        backgroundColor = "";
      }

      return {
        style: {
          background: backgroundColor,
        },
      };
    },

    customToolbar: () => {
      return (
        <div style={{ padding: "15px" }}>
          {" "}
          <h2
            style={{
              position: "absolute",
              top: "35px",
              left: "10px",
              zIndex: 999,
            }}
          >
            Bulk Order list
          </h2>
        </div>
      );
    },

    onFilterChange: (column, filterList) => {
      // Convert the filter value to a Date object
      const start = new Date(startDate);
      const end = new Date(endDate);
      const createdAtColumnIndex = columns.findIndex(
        (column) => column.name === "createdAt"
      );
      return filterList.map((value, colIndex) => {
        if (colIndex === createdAtColumnIndex) {
          const dateValue = new Date(value);

          // Check if the date falls within the selected range
          return dateValue >= start && dateValue <= end;
        }
        return true;
      });
    },

    customToolbar: () => {
      return (
        <div style={{ padding: "15px" }}>
          {" "}
          <h2
            style={{
              position: "absolute",
              top: "-20px",
              left: "10px",
            }}
          >
            Bulk Order list
          </h2>
          <h2
            style={{
              position: "absolute",
              top: "35px",
              left: "10px",
              zIndex: 999,
            }}
          >
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Insurance" />
            </FormGroup>
          </h2>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              // marginTop: "-8%",
              marginLeft: "-10rem",
              alignItems: "center",
            }}
          >
            <select
              style={{
                marginRight: "10px",
                padding: "10px",
                border: "1px solid #38b6ff",
                borderRadius: "4px",
                width: "Auto",
              }}
            >
              <option value="">city</option>
              <option value="city1">Delhi</option>
              <option value="city2">Pune</option>
              <option value="city3">Noida</option>
              {/* Add more city options as needed */}
            </select>
            <select
              style={{
                marginRight: "10px",
                padding: "10px",
                border: "1px solid #38b6ff",
                borderRadius: "4px",
                width: "Auto",
              }}
            >
              <option value="">Customer Type</option>
              <option value="city1">Basic</option>
              <option value="city2">Premium</option>
            </select>

            {/* {dateError && <div style={{ color: "red" }}>{dateError}</div>} */}
            <label htmlFor="start-date" style={{ fontWeight: "bold" }}>
              From:
            </label>
            {/* {dateError && <div style={{ color: "red" }}>{dateError}</div>} */}
            <input
              style={{
                marginRight: "10px",
                padding: "10px",
                border: "1px solid #38b6ff",
                borderRadius: "4px",
                width: "18%",
              }}
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              placeholder="Start Date"
              onBlur={handleDateBlur}
              max={formatTodayDate()}
            />

            <label htmlFor="start-date" style={{ fontWeight: "bold" }}>
              To:
            </label>
            <input
              style={{
                marginRight: "10px",
                padding: "8px",
                border: "1px solid #38b6ff",
                borderRadius: "4px",
                width: "18%",
              }}
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              placeholder="End Date"
              onBlur={handleDateBlur}
              max={formatTodayDate()}
            />
          </div>
        </div>
      );
    },

    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div
          style={{
            position: "absolute",
            // top: "60px", // Adjust this value to control the gap
            right: "10px",
            marginTop: "4px",
            zIndex: 999,
          }}
        >
          <input
            style={{
              borderRadius: "4px",
              height: "22px",
              border: "1px solid",
              borderColor: "#38b6ff",
              marginRight: "10px",
              padding: "5px",
            }}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </div>
      );
    },
  };

  console.log(formattedData, "00000000000");
  const formatTime = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      const formattedDate = `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${formattedDate} :: ${hours}:${minutes}:${seconds}`;
    }
    return ""; // Return an empty string if timestamp is null
  };

  function countOrderStatus(orders) {
    // Initialize counters
    let assignedCount = 0;
    let rejectedCount = 0;
    let cancelledCount = 0;
    let hangedCount = 0;
    let pickedupCount = 0;
    let deliveredCount = 0;
    let acceptedCount = 0;
    let tripendedCount = 0;
    let intransitCount = 0;
    let tripstartedCount = 0;
    let arrivedCount = 0;

    // Iterate over each order in the array
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      const orderStatus = order.orderStatus;

      // Increment respective counter based on order status
      if (orderStatus === "Assigned") {
        assignedCount++;
      } else if (orderStatus === "Rejected") {
        rejectedCount++;
      } else if (orderStatus === "Cancelled") {
        cancelledCount++;
      } else if (orderStatus === "hanged") {
        hangedCount++;
      } else if (orderStatus === "PickedUp") {
        pickedupCount++;
      } else if (orderStatus === "Delivered") {
        deliveredCount++;
      } else if (orderStatus === "Accepted") {
        acceptedCount++;
      } else if (orderStatus === "TripEnded") {
        tripendedCount++;
      } else if (orderStatus === "inTransit") {
        intransitCount++;
      } else if (orderStatus === "TripStarted") {
        tripstartedCount++;
      } else if (orderStatus === "Arrived") {
        arrivedCount++;
      }
    }
    // Return an object with the counts
    return {
      assigned: assignedCount,
      rejected: rejectedCount,
      cancelled: cancelledCount,
      hanged: hangedCount,
      pickedup: pickedupCount,
      delivered: deliveredCount,
      accepted: acceptedCount,
      tripended: tripendedCount,
      intransit: intransitCount,
      tripstarted: tripstartedCount,
      arrived: arrivedCount,
    };
  }

  const checkColor = (status) => {
    console.log("Status:", status);
    let backgroundColor;

    if (status === "Created") {
      backgroundColor = "#1976D2";
    } else if (status === "Assigned") {
      backgroundColor = "#1976D2";
    } else if (status === "Accepted") {
      backgroundColor = "#1976D2";
    } else if (status === "TripStarted") {
      backgroundColor = "#1976D2";
    } else if (status === "Arrived") {
      backgroundColor = "#1976D2";
    } else if (status === "PickedUp") {
      backgroundColor = "#1976D2";
    } else if (status === "inTransit") {
      backgroundColor = "#1976D2";
    } else if (
      status === "Delivered at drop 1" ||
      status === "Delivered at drop 2" ||
      status === "Delivered at drop 3" ||
      status === "Delivered at drop 4" ||
      status === "Delivered at drop 5" ||
      status === "Delivered at drop 6"
    ) {
      backgroundColor = "#3FB00A";
    } else if (status === "TripEnded") {
      backgroundColor = "#151B54";
    }

    return {
      style: {
        background: backgroundColor,
      },
    };
  };

  const handleClicka = (gridName) => {
    setSelectedGrid(gridName);
    setDdata(userdata.data);
  };

  const orderCounts = countOrderStatus(userdata.data);

  const numRows = orderData?.data.length;
  console.log(numRows, "!!!!!!");

  const handleDeliverClick = (status) => {
    setSelectedGrid(status);
    const DeliveredRows = orderData?.data.filter(
      (row) => row.orderStatus === status
    );
    setDdata(DeliveredRows);
  };

  console.log(ddata, "--------");

  console.log(rdata, "+++!!!222");

  const CustomBodyRenderLite = ({ tableMeta }) => {
    const handleRowClick = () => {
      const rowData = tableMeta.rowData;

      return rowData;
    };

    // const fData = {
    //   name:"susheel",
    //   address:"Delhi"
    // }

    return (
      <>
        <Stack direction="row" spacing={1}>
          <Link
            to="/editbulk"
            onClick={handleRowClick}
            state={{ from: handleRowClick() }}
          >
            <Button variant="contained" size="small" color="primary">
              <EditIcon fontSize="small" />
            </Button>
          </Link>
          {/* Additional buttons and components */}
        </Stack>
      </>
    );
  };

  const columns = [
    {
      name: "bulkOrderId",
      label: "bulkOrder ID",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "orderId",
      label: "Order ID",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "createdAt",
      label: "createdAt",
      options: {
        filter: false,
        display: false,
        customBodyRender: (value) => formatTime(value),
      },
    },
    {
      name: "updatedAt",
      label: "updatedAt",
      options: {
        filter: false,
        display: false,
        customBodyRender: (value) => formatTime(value),
      },
    },

    {
      name: "id",
      label: "Task ID",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "orderType",
      label: "Order Type",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "senderId",
      label: "Sender Id",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "orderContent",
      label: "Order Content",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "senderPhone",
      label: "Sender Phone",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "totalDistance",
      label: "Total Distance",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "weightCategory",
      label: "Weight Category",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "weight",
      label: "Weight",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "appliedPromoCode",
      label: "Applied PromoCode",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "orderStatus",
      label: "OrderStatus",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "deliveredCount",
      label: "Delivered Count",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "deliveryCount",
      label: "Delivery Count",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (rowData, tableMeta, updateValue) =>
          CustomBodyRenderLite({ rowData, tableMeta, updateValue }),
      },
    },
  ];

  console.log(Rows, "+++++++------++====");

  return (
    <Grid style={{ padding: "10px" }}>
      <Grid container spacing={3} style={pointerStyle}>
        <Grid item xs={2}>
          <Paper
            onClick={() => handleClicka("grid")}
            style={{
              backgroundColor: selectedGrid === "grid" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                {" "}
                {/* Add textAlign property */}
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  <Typography>{userdata.data.length}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Total order </Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("Assigned")}
            style={{
              backgroundColor:
                selectedGrid === "Assigned" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  <Typography>{orderCounts.assigned}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Assigned</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("Accepted")}
            style={{
              backgroundColor:
                selectedGrid === "Accepted" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.accepted}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Accepted</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("TripStarted")}
            style={{
              backgroundColor:
                selectedGrid === "TripStarted" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.tripstarted}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders TripStarted</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("Arrived")}
            style={{
              backgroundColor:
                selectedGrid === "Arrived" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.arrived}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Arrived</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("PickedUp")}
            style={{
              backgroundColor:
                selectedGrid === "PickedUp" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.pickedup}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders PickedUp</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("inTransit")}
            style={{
              backgroundColor:
                selectedGrid === "inTransit" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.intransit}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders inTransit</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("Delivered")}
            style={{
              backgroundColor:
                selectedGrid === "Delivered" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  <Typography>{orderCounts.delivered}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Delivered</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("TripEnded")}
            style={{
              backgroundColor:
                selectedGrid === "TripEnded" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.tripended}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders TridEnded</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("hanged")}
            style={{
              backgroundColor:
                selectedGrid === "hanged" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.hanged}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Hanging Order</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("Rejected")}
            style={{
              backgroundColor:
                selectedGrid === "Rejected" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.rejected}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Rejected</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={2}>
          <Paper
            onClick={() => handleDeliverClick("Cancelled")}
            style={{
              backgroundColor:
                selectedGrid === "Cancelled" ? "#1976D2" : "#38b6ff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Box borderRadius="50%"></Box>
              </div>
              <div style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    color: "black",
                    marginBottom: "0px",
                    marginButton: "5px",
                  }}
                >
                  <Typography>{orderCounts.cancelled}</Typography>
                </Typography>
                <Typography style={{ color: "black" }}>
                  <Box fontSize={12}>Orders Cancelled</Box>
                </Typography>
              </div>
              <div />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "30px", height: "100%" }}>
        <MUIDataTable
          // title="Order list"
          data={ddata ? ddata : orderData?.data}
          columns={columns}
          options={options}
          rows={Rows}
        />
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        {formattedData?.map((item, index) => {
          return (
            <div style={{ padding: 20, width: "985px" }} key={index}>
              <Typography
                variant="h5"
                style={{ marginBottom: 8, marginTop: 10 }}
              >
                Task Details
              </Typography>
              <Typography variant="h5" style={{ marginBottom: 2 }}>
                ORDER ID : {item.orderId}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                  gap: "10px",
                }}
              >
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#c5e7b5",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Order status</Typography>
                  <br />
                  {item.orderStatus}
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Created Data</Typography>
                  <br />
                  <div>{formatTime(item.createdAt)}</div>
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Updated Data</Typography>
                  <br />
                  <div>{formatTime(item.updatedAt)}</div>
                </box>
              </div>

              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              <Typography variant="h6">Active Rider</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                  gap: "20px",
                }}
              >
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Rider Id</Typography>
                  <Divider />
                  <br />
                  <div>{item.activeRider.riderId}</div>
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Rider name</Typography>
                  <Divider />
                  <br />
                  <div>{item.activeRider.riderName}</div>
                </box>
              </div>

              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ########################### Order details ####################### */}
              <box>
                <box style={{ padding: 20 }}>
                  <Typography variant="h6">Order details</Typography>
                  <br />
                  <Typography
                    sx={{
                      justifyContent: "center",
                      marginBottom: 2,
                      marginLeft: 12,
                    }}
                  >
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Applied Promocode:</strong>
                            {item.appliedPromoCode}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>BulkOrderId:</strong> {item.bulkOrderId}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Arrived To Time:</strong>
                            {formatTime(item.arriveToTime)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Arrived from time:</strong>{" "}
                            {formatTime(item.arriveFromTime)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Customer rating:</strong>
                            {item.customerRating}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Delivery count:</strong>{" "}
                            {item.deliveredCount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Id:</strong>
                            {item.id}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Is fresh Order:</strong> {item.isFreshOrder}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Is Payback Amount Deposited:</strong>
                            {item.isPayBackAmountDeposited}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Is Scheduled:</strong> {item.isScheduled}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Order CancelledBy:</strong>
                            {item.orderCancelledBy}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Order Content:</strong> {item.orderContent}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Order date:</strong>
                            {formatTime(item.orderDate)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Oder Type:</strong> {item.orderType}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Payback Payment Mode:</strong>
                            {item.paybackPaymentMode}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Payback Upi:</strong> {item.paybackUpi}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Reason:</strong>
                            {item.reason}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Rider rating:</strong> {item.riderRating}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Sender Id :</strong>
                            {item.senderId}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Sender Phone:</strong> {item.senderPhone}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Toatal Distance:</strong>
                            {item.totalDistance}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Toatal payback Amount:</strong>{" "}
                            {item.totalPaybackAmount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Weight :</strong>
                            {item.weight}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Weight Category:</strong>{" "}
                            {item.weightCategory}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Payback Deposite Location :</strong>
                            {item.paybackDepositLocation}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Weight Category:</strong>{" "}
                          {item.weightCategory}
                        </Typography>
                      </Grid> */}
                      </Grid>
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ textAlign: "center", flex: 1 }}>
                        <Typography variant="h6">
                          payBackPayentImageUrl
                        </Typography>
                        <div
                          onClick={toggleZoom3}
                          style={{
                            ...zoomedImageStyle,
                            ...(zoomed3 && zoomedImageZoomedStyle),
                            margin: "1px auto",
                            maxWidth: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", // Center content vertically
                          }}
                        >
                          <img
                            style={{
                              ...zoomableImageStyle,
                              ...(zoomed3 && { transform: "scale(2)" }),
                            }}
                            src={item.payBackPayentImageUrl}
                            alt="pending"
                          />
                        </div>
                      </div>
                    </div>
                  </Typography>
                </box>
              </box>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />

              {/* ########################### End Order details ####################### */}
              <box style={{}}>
                <Typography variant="h6">Order Timeline</Typography>
                <Timeline align="alternate">
                  {item.timeline.map((data, index) => (
                    <TimelineItem key={index} style={{ marginRight: "40%" }}>
                      <TimelineOppositeContent>
                        {/* <Typography color="textSecondary">
                          {formatTime(data.time.seconds)}
                        </Typography> */}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot style={checkColor(data.status).style} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Typography>{data.status}</Typography>
                          <Typography color="textSecondary">
                            {formatTime(data.time)}
                          </Typography>
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </box>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />

              {/* ######################## assigned rider #################### */}

              <div>
                <Typography variant="h6">Assigned rider</Typography>
                <box>
                  <Typography
                    sx={{
                      justifyContent: "center",
                      marginBottom: 2,
                      marginLeft: 12,
                    }}
                  >
                    {item.assignedRider.map((data, index) => (
                      <box style={{ padding: 20, marginTop: 10 }} key={index}>
                        <Typography variant="h6">
                          Rider : {index + 1}
                        </Typography>

                        <br />

                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>orderId:</strong>
                                {data.orderId}
                              </Typography>
                            </Grid>

                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>id:</strong>
                                {data.id}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>GeoCode:</strong>
                                {data.currentLocation._lat}
                                <br />
                                {data.currentLocation._long}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>phoneNumber:</strong> {data.phoneNumber}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                      </box>
                    ))}
                  </Typography>
                </box>
              </div>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ########################### End assigned rider ####################### */}

              <box>
                <box style={{ padding: 20 }}>
                  <Typography variant="h6">Pickup Location</Typography>
                  <br />
                  <Typography
                    sx={{
                      justifyContent: "center",
                      marginBottom: 2,
                      marginLeft: 12,
                    }}
                  >
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Address:</strong>
                            {item.pickUpLocation.address}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Additional_Information:</strong>{" "}
                            {item.pickUpLocation.additionalInfo}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Payback name:</strong>
                            {item.pickUpLocation.paybackName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Contact_Number:</strong>{" "}
                            {item.pickUpLocation.contactNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>GeoCode:</strong>
                            {item.pickUpLocation.geoCode._lat}
                            {item.pickUpLocation.geoCode._long}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Name:</strong> {item.pickUpLocation.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Otp:</strong>
                            {item.pickUpLocation.otp}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Payment_mode:</strong>{" "}
                          {item.pickUpLocation.paymentMode}
                        </Typography>
                      </Grid> */}
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Delivery Status:</strong>
                            {item.pickUpLocation.deliveryStatus}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Is OTP submitted:</strong>{" "}
                            {item.pickUpLocation.hasOwnProperty(
                              "isOtpSubmitted"
                            )
                              ? item.pickUpLocation.isOtpSubmitted.toString()
                              : "Not available"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Payback amount collected:</strong>

                            {item.pickUpLocation.hasOwnProperty(
                              "isPaybackAmountCollected"
                            )
                              ? item.pickUpLocation.isPaybackAmountCollected.toString()
                              : "Not available"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Payback amount:</strong>{" "}
                            {item.pickUpLocation.paybackAmount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>hasCashOnDeliveryAmount:</strong>

                            {item.pickUpLocation.hasOwnProperty(
                              "hasCashOnDeliveryAmount"
                            )
                              ? item.pickUpLocation.hasCashOnDeliveryAmount.toString()
                              : "Not available"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>isCashOnDeliveryAmountCollected:</strong>{" "}
                            {item.pickUpLocation.hasOwnProperty(
                              "isCashOnDeliveryAmountCollected"
                            )
                              ? item.pickUpLocation.isCashOnDeliveryAmountCollected.toString()
                              : "Not available"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Reason:</strong>
                            {item.pickUpLocation.reason}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ textAlign: "center", flex: 1 }}>
                        <Typography variant="h6">Payment ImageUrl</Typography>
                        <div
                          onClick={toggleZoom}
                          style={{
                            ...zoomedImageStyle,
                            ...(zoomed && zoomedImageZoomedStyle),
                            margin: "1px auto",
                            maxWidth: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center", // Center content vertically
                          }}
                        >
                          <img
                            style={{
                              ...zoomableImageStyle,
                              ...(zoomed && { transform: "scale(2)" }),
                            }}
                            src={item.pickUpLocation.paymentImageUrl}
                            alt="Payment"
                          />
                        </div>
                      </div>
                      <div style={{ textAlign: "center", flex: 1 }}>
                        <Typography variant="h6">Signature Url</Typography>
                        <div
                          onClick={toggleZoom1}
                          style={{
                            ...zoomedImageStyle,
                            ...(zoomed1 && zoomedImageZoomedStyle),
                            margin: "1px auto",
                            maxWidth: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{
                              ...zoomableImageStyle,
                              ...(zoomed1 && { transform: "scale(2)" }),
                            }}
                            src={item.pickUpLocation.validatorImageUrl}
                            alt="Signature"
                          />
                        </div>
                      </div>
                    </div>
                  </Typography>
                </box>
                <Divider sx={{ height: "10px", fontWeight: "bold" }} />
                {/* ####################   Drop location #################### */}
                <div>
                  {item.dropLocations.map((data, index) => (
                    <box style={{ padding: 20, marginTop: 10 }} key={index}>
                      <Typography variant="h6">Drop Location</Typography>
                      <br />
                      <Typography
                        sx={{
                          justifyContent: "center",
                          marginBottom: 2,
                          marginLeft: 12,
                        }}
                      >
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Address:</strong>
                                {data.address}
                              </Typography>
                            </Grid>

                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Additional_Information:</strong>
                                {data.additionalInfo}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Contact_Number:</strong>
                                {data.contactNumber}
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Amount:</strong>
                              {data.amount}
                            </Typography>
                          </Grid> */}
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>GeoCode:</strong>
                                {data.geoCode._lat}
                                <br />
                                {data.geoCode._long}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Name:</strong>
                                {data.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Otp:</strong>
                                {data.otp}
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Payment_mode:</strong>
                              {data.paymentMode}
                            </Typography>
                          </Grid> */}
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Delivery Status:</strong>
                                {data.deliveryStatus}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Isotp submitted:</strong>{" "}
                                {data.isOtpSubmitted}
                                {data.hasOwnProperty("isOtpSubmitted")
                                  ? data.isOtpSubmitted.toString()
                                  : "Not available"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Payback amount collected:</strong>

                                {data.hasOwnProperty("isPayBackAmountCollected")
                                  ? data.isPayBackAmountCollected.toString()
                                  : "Not available"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Payback amount:</strong>{" "}
                                {data.paybackAmount}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>hasCashOnDeliveryAmount:</strong>

                                {data.hasOwnProperty("hasCashOnDeliveryAmount")
                                  ? data.hasCashOnDeliveryAmount.toString()
                                  : "Not available"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>
                                  isCashOnDeliveryAmountCollected:
                                </strong>{" "}
                                {data.hasOwnProperty(
                                  "isCashOnDeliveryAmountCollected"
                                )
                                  ? data.isCashOnDeliveryAmountCollected.toString()
                                  : "Not available"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ display: "flex", marginLeft: 10 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <strong>Reason:</strong>
                                {data.reason}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ textAlign: "center", flex: 1 }}>
                            <Typography variant="h6">
                              Payment ImageUrl
                            </Typography>
                            <div
                              onClick={() => toggleZooms(index, "payment")}
                              style={{
                                ...zoomedImageStyle,
                                ...(zoomStates[index]?.payment &&
                                  zoomedImageZoomedStyle),
                                margin: "1px auto",
                                maxWidth: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  ...zoomableImageStyle,
                                  ...(zoomStates[index]?.payment && {
                                    transform: "scale(2)",
                                  }),
                                }}
                                src={data.paymentImageUrl}
                                alt="Payment"
                              />
                            </div>
                          </div>
                          <div style={{ textAlign: "center", flex: 1 }}>
                            <Typography variant="h6">Signature Url</Typography>
                            <div
                              onClick={() => toggleZooms(index, "signature")}
                              style={{
                                ...zoomedImageStyle,
                                ...(zoomStates[index]?.signature &&
                                  zoomedImageZoomedStyle),
                                margin: "1px auto",
                                maxWidth: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  ...zoomableImageStyle,
                                  ...(zoomStates[index]?.signature && {
                                    transform: "scale(2)",
                                  }),
                                }}
                                src={data.validatorImageUrl}
                                alt="Signature"
                              />
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </box>
                  ))}
                </div>
              </box>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ####################  End Drop location #################### */}

              {/* ############################# payment information ########################## */}
              <box style={{ padding: 20 }}>
                <Typography variant="h6">Payment information</Typography>
                <br />
                <Typography
                  sx={{
                    justifyContent: "center",
                    marginBottom: 2,
                    marginLeft: 12,
                  }}
                >
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>AdditionalDropLocationCharge:</strong>
                          {item.paymentInformation.additionalDropCharges}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>CasedeliveryCharge:</strong>{" "}
                          {item.paymentInformation.cashOnDeliveryCharges}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>DistanceCharge:</strong>{" "}
                          {item.paymentInformation.distanceCharges}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>InsuranceAmount:</strong>{" "}
                          {item.paymentInformation.insuranceAmount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>OrderAmount:</strong>{" "}
                          {item.paymentInformation.orderAmount}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>PaymentMode:</strong>{" "}
                          {item.paymentInformation.paymentMode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>PaymentRemark:</strong>{" "}
                          {item.paymentInformation.paymentRemark}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>PromoCodeDiscount:</strong>{" "}
                          {item.paymentInformation.promoCodeDiscount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>RiderAmount:</strong>{" "}
                          {item.paymentInformation.riderAmount}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>TransactionId:</strong>{" "}
                          {item.paymentInformation.transactionId}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>WeightCharges:</strong>{" "}
                          {item.paymentInformation.weightCharges}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Rider Reward Amount:</strong>{" "}
                          {item.paymentInformation.riderRewardAmount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ display: "flex", marginLeft: 10 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Return charges:</strong>{" "}
                          {item.paymentInformation.weightCharges}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>Admin Commission:</strong>{" "}
                          {item.paymentInformation.adminCommission}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Typography>
              </box>
              {/* ############################# end payment information ########################## */}
            </div>
          );
        })}
      </Drawer>
    </Grid>
  );
};

export default BulkOrder;
