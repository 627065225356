import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  TextField,
  Grid,
  Box,
  Divider,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Usermanage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [usertype, setUsertype] = useState("");
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [checkboxValid, setCheckboxValid] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [usertypeError, setUsertypeError] = useState("");

  const pointerStyle = {
    cursor: "pointer",
  };
  const paperStyle = {
    backgroundColor: "#1976D2", // Default color
  };

  const paperHoverStyle = {
    backgroundColor: "#38b6ff", // Hover color
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d{0,10}$/.test(inputValue)) {
      setPhone(inputValue);
      setPhoneError("");
    } else {
      setPhoneError("Please enter a valid phone number");
    }
  };

  const handleCheckboxChange = (e) => {
    setChecked1(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange1 = (e) => {
    setChecked2(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange2 = (e) => {
    setChecked3(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange3 = (e) => {
    setChecked4(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const handleCheckboxChange4 = (e) => {
    setChecked5(e.target.checked);

    if (e.target.checked) {
      setCheckboxValid(true);
    } else {
      setCheckboxValid(false);
    }
  };

  const validateInput = (inputValue, fieldName) => {
    if (inputValue === "") {
      switch (fieldName) {
        case "name":
          setNameError("Please enter name");
          break;
        case "email":
          setEmailError("Please enter email");
          break;
        case "phone":
          setPhoneError("Please enter Phone");
          break;
        case "password":
          setPasswordError("Please enter Password");
          break;
        case "usertype":
          setUsertypeError("Please enter User Type");
          break;

        default:
          break;
      }
      return false;
    }
    // Clear error when validation succeeds
    switch (fieldName) {
      case "name":
        setNameError("");
        break;
      case "email":
        setEmailError("");
        break;
      case "phone":
        setPhoneError("");
        break;
      case "usertype":
        setUsertypeError("");
        break;

      default:
        break;
    }
    return true;
  };

  // const validateInput = (inputValue) => {
  //   return inputValue !== ""; // Basic validation: check if a value is selected
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    const isNameValid = validateInput(name, "name");
    const isEmailValid = validateInput(email, "email");
    const isPhoneValid = validatePhone();
    const isPasswordValid = validateInput(password, "password");
    const isUserTypeValid = validateInput(usertype, "usertype");
    if (
      isNameValid &&
      isEmailValid &&
      isPhoneValid &&
      isPasswordValid &&
      isUserTypeValid
    ) {
      console.log("Form submitted successfully");
      handleClose1();
      setName("");
      setEmail("");
      setPhone("");
      setPassword("");
      setUsertype("");
      setCheckboxValid(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setChecked5(false);

      const payload = {
        name,
        email,
        phone,
        password,
        checked1,
        checked2,
      };

      console.log("Payload:", payload);
    } else {
      console.log("Form validation failed");
    }
  };
  // Function to validate phone number
  const validatePhone = () => {
    if (phone === "") {
      setPhoneError("Please enter Phone");
      return false;
    } else {
      setPhoneError(""); // Clear phone error when input is valid
      return true;
    }
  };

  // const handleSave = () => {
  //   if (!isDuplicateEntry(name, email, password, phone, usertype)) {
  //     const newRow = [
  //       name,
  //       email,
  //       password,
  //       phone,
  //       usertype,
  //       checked1 ? "Yes" : "No",
  //       checked2 ? "Yes" : "No",
  //       checked3 ? "Yes" : "No",
  //       checked4 ? "Yes" : "No",
  //       checked5 ? "Yes" : "No",
  //     ];
  //     setData([...data, newRow]);
  //     setName("");
  //     setPhone("");
  //     setEmail("");
  //     setPassword("");
  //     setUsertype("");
  //     setOpen1(false);
  //   } else {
  //     alert("Duplicate entry! Please enter unique values.");
  //   }
  // };
  // const isDuplicateEntry = (name, email, password, phone, usertype) => {
  //   return data.some(
  //     (data) =>
  //       data[0] === name &&
  //       data[1] === email &&
  //       data[2] === password &&
  //       data[3] === phone &&
  //       data[4] === usertype
  //   );
  // };
  const options = {
    filter: true,
    search: true,
    pagination: true,
    selectableRowsHideCheckboxes: true,
    onRowClick: () => handleOpen(),
  };
  const handleOpen = () => {
    setOpenDrawer(true);
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleSubmitButtonClick = () => {
    setEditMode(false);
  };

  return (
    <div style={{ padding: "10px" }}>
      <Grid
        item
        xs={6}
        sm={6}
        lg={4}
        xl={2}
        sx={{ maxWidth: "20%" }}
        style={pointerStyle}
      >
        <Paper
          onClick={handleOpen1}
          style={
            isHovered2 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
          }
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Box
                borderRadius="50%"
                width="30px"
                height="40px"
                style={{}}
              ></Box>
            </div>
            <div>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "5px",
                }}
              ></Typography>
              <Typography style={{ color: "black" }}>
                <Box fontSize={12}>Create user</Box>
              </Typography>
            </div>
            <div />
          </div>
        </Paper>
      </Grid>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography variant="h6" align="left">
              User Management
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={handleClose1}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(""); // Clear error when input changes
                  }}
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && nameError && (
                  <span style={{ color: "red" }}>{nameError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && emailError && (
                  <span style={{ color: "red" }}>{emailError}</span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="Phone"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handlePhoneChange} // Use the new handler
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && phoneError && (
                  <span style={{ color: "red" }}>{phoneError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && passwordError && (
                  <span style={{ color: "red" }}>{passwordError}</span>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ mt: 3, minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    User Type
                  </InputLabel>
                  <Select
                    value={usertype}
                    onChange={(e) => {
                      setUsertype(e.target.value);
                      setUsertypeError("");
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    // required={true}
                    label="User Type"
                  >
                    <MenuItem value="Super Admin">Super Admin</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                  </Select>
                  {isSubmitted && usertypeError && (
                    <span style={{ color: "red" }}>{usertypeError}</span>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <FormGroup
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked1}
                    onChange={handleCheckboxChange}
                  />
                }
                label="order list"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked2}
                    onChange={handleCheckboxChange1}
                  />
                }
                label="Rider list"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked3}
                    onChange={handleCheckboxChange2}
                  />
                }
                label="Customer list"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked4}
                    onChange={handleCheckboxChange3}
                  />
                }
                label="Issue ticket"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked5}
                    onChange={handleCheckboxChange4}
                  />
                }
                label="Admin"
              />
            </FormGroup>
            {isSubmitted && !checkboxValid && (
              <span style={{ color: "red" }}>
                Please select at least one checkbox
              </span>
            )}
            <Button
              sx={{
                display: "flex",
                minWidth: 220,
                margin: "0 auto",
                marginTop: "5%",
              }}
              type="submit"
              ml={10}
              variant="contained"
              size="large"
              color="primary"
              mt={3}
              //onClick={handleSave}
            >
              Create user
            </Button>
          </Box>
        </form>
      </Modal>
      <Grid sx={{ mt: 1 }}>
        <MUIDataTable
          title="Show user details"
          columns={columns}
          data={data}
          options={options}
        />
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        <div style={{ padding: 20, width: "985px" }}>
          <Typography variant="h4" style={{ marginBottom: 10 }}>
            Add vehicle all charges
          </Typography>

          <Box
            sx={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "16px",
              //   maxWidth: "800px",
              margin: "0 auto",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <Typography variant="body1">user details</Typography>
            <>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    disabled={!editMode}
                    type="text"
                    id="outlined-disabled"
                    label="Name"
                    defaultValue="Hello World"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    disabled={!editMode}
                    type="text"
                    id="outlined-disabled"
                    label="Email"
                    defaultValue="Hello World"
                  />
                </Grid>
              </Grid>

              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      disabled={!editMode}
                      type="number"
                      id="outlined-disabled"
                      label="Phone"
                      defaultValue="Hello World"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      disabled={!editMode}
                      type="password"
                      id="outlined-disabled"
                      label="password"
                      defaultValue="password"
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <FormControl
                      sx={{ mt: 3, minWidth: "100%" }}
                      variant="outlined"
                      m={2}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        User Type
                      </InputLabel>
                      <Select
                        disabled={!editMode}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        required={true}
                        label="User Type"
                      >
                        <MenuItem value="Super Admin">Super Admin</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="Manager">Manager</MenuItem>
                        <MenuItem value="User">User</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup sx={{ flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!editMode}
                            checked={checked1}
                            onChange={(e) => setChecked1(e.target.checked)}
                          />
                        }
                        label="order list"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!editMode}
                            checked={checked2}
                            onChange={(e) => setChecked2(e.target.checked)}
                          />
                        }
                        label="Rider list"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!editMode}
                            checked={checked3}
                            onChange={(e) => setChecked3(e.target.checked)}
                          />
                        }
                        label="Customer list"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!editMode}
                            checked={checked4}
                            onChange={(e) => setChecked4(e.target.checked)}
                          />
                        }
                        s
                        label="Issue ticket"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!editMode}
                            checked={checked5}
                            onChange={(e) => setChecked5(e.target.checked)}
                          />
                        }
                        s
                        label="Admin"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
            </>
          </Box>
        </div>
        {editMode ? (
          <Button
            sx={{ m: 3, maxWidth: "70px" }}
            type="submit"
            variant="contained"
            color="primary"
            mt={3}
            onClick={handleSubmitButtonClick}
          >
            Submit
          </Button>
        ) : (
          <Button
            sx={{ m: 3, maxWidth: "70px" }}
            type="submit"
            variant="contained"
            color="primary"
            mt={3}
            onClick={handleEditButtonClick}
          >
            Edit
          </Button>
        )}
      </Drawer>
    </div>
  );
};
const columns = [
  {
    name: "Name",
    label: "Name",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Email",
    label: "Email",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Phone",
    label: "Phone",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Password",
    label: "Password",
    options: {
      filter: false,
      display: false,
    },
  },
  {
    name: "User Type",
    label: "User Type",
    options: {
      filter: false,
      display: true,
    },
  },

  {
    name: "Order",
    label: "Order",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Rider",
    label: "Rider",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Customer",
    label: "Customer",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Issue ticket",
    label: "Issue ticket",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Admin",
    label: "Admin",
    options: {
      filter: false,
      display: true,
    },
  },
  // {
  //   name: "Action",
  //   label: "Action",
  //   options: {
  //     filter: false,
  //     display: true,
  //sort: false,
  //     customBodyRenderLite: (dataIndex, rowIndex) => {
  //       return (
  //         <>
  //           <Stack direction="row" spacing={1}>
  //             <Link to="/editorder">
  //               <Button variant="contained" size="small" color="primary">
  //                 <EditIcon fontSize="small" />
  //               </Button>
  //             </Link>
  //             {/* <Button m={1}
  //                               variant="contained"
  //                               size="small"
  //                               style={{ backgroundColor: "red" }} onClick={() => handleDelete(rowIndex)}><DeleteIcon fontSize="small" /></Button> */}
  //           </Stack>
  //         </>
  //       );
  //     },
  //   },
  // },
];
export default Usermanage;
