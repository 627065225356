import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Riders from "./pages/Riders";
import Customer from "./pages/Customer";
import Settings from "./pages/Settings";
import Driver from "./pages/Driver";
import Login from "./pages/Login";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Forget from "./pages/Forget";
import Editorder from "./pages/Editorder";
import Orderlist from "./pages/Orderlist";
import Editrider_detail from "./pages/Editrider_detail";
import Userhandled from "./pages/Userhandled";
import Customerissues from "./pages/Customerissues";
import Riderissues from "./pages/Riderissues";
import Maps from "./pages/Maps";
import Geolocation from "./pages/Geolocation";
import Vehicle from "./pages/Vehicle";
import Usermanagement from "./pages/Usermanagement";
import Editcustomers from "./pages/Editcustomers";
import Addcitymaps from "./pages/Addcitymaps";
import Offers from "./pages/Offers";
import CustomerDiscounts from "./pages/CustomerDiscounts";
import Notifications from "./pages/Notifications";
import Issue from "./pages/Issue";
import BulkOrderList from "./pages/BulkOrderList";
import EditbulkOrder from "./pages/EditbulkOrder";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <UserAuthContextProvider>
          <Routes>
            <Route path="/" exact element={<Login />}></Route>
            <Route path="/forget" exact element={<Forget />}></Route>
            <Route path="/home" exact element={<Home />}></Route>
            <Route path="/riders" exact element={<Riders />}></Route>
            <Route path="/editorder" exact element={<Editorder />}></Route>
            <Route path="/editbulk" exact element={<EditbulkOrder />}></Route>
            <Route
              path="/editcustomer"
              exact
              element={<Editcustomers />}
            ></Route>
            <Route
              path="/editriderdetails"
              exact
              element={<Editrider_detail />}
            ></Route>
            <Route
              path="/bulkorderlist"
              exact
              element={<BulkOrderList />}
            ></Route>
            <Route path="/orderlist" exact element={<Orderlist />}></Route>
            <Route path="/customer" exact element={<Customer />}></Route>
            <Route path="/user" exact element={<Userhandled />}></Route>
            <Route path="/vehicle" exact element={<Vehicle />}></Route>
            <Route path="/settings" exact element={<Settings />}></Route>
            <Route path="/driver" exact element={<Driver />}></Route>
            <Route
              path="/customerissue"
              exact
              element={<Customerissues />}
            ></Route>
            <Route path="/riderissue" exact element={<Riderissues />}></Route>
            <Route path="/addmap" exact element={<Maps />}></Route>
            <Route
              path="/usermanagement"
              exact
              element={<Usermanagement />}
            ></Route>
            <Route
              path="/notification"
              exact
              element={<Notifications />}
            ></Route>
            <Route path="/issue" exact element={<Issue />}></Route>
            <Route path="/offer" exact element={<Offers />}></Route>
            <Route
              path="/customerdiscounts"
              exact
              element={<CustomerDiscounts />}
            ></Route>
            <Route path="/geolocation" exact element={<Geolocation />}></Route>
            <Route path="/addcitymap" exact element={<Addcitymaps />}></Route>
          </Routes>
        </UserAuthContextProvider>
      </BrowserRouter>
    </>
  );
}
