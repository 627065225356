import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { getPostData } from "../redux/action/map_action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  Box,
  Divider,
  Typography,
  Paper,
  IconButton,
  Modal,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Addcitymap = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.post);
  const [open1, setOpen1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [city, setCity] = useState("");
  const [data, setData] = useState([]);
  const [mapdata, setMapdata] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  console.log("object", userdata);
  useEffect(() => {
    if (userdata.data.length) {
      setMapdata(userdata);
    } else {
      console.log("Data is not available");
    }
  }, [userdata]);

  console.log("11111@@", mapdata);

  const pointerStyle = {
    cursor: "pointer",
  };
  const paperStyle = {
    backgroundColor: "#95B9C7", // Default color
  };

  const paperHoverStyle = {
    backgroundColor: "#6698FF", // Hover color
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  console.log("vehicle", vehicle);

  const handleSave = () => {
    navigate("/geolocation");
  };
  // const isDuplicateEntry = (vehicle, city) => {
  //   return data.some((data) => data[0] === city && data[1] === vehicle);
  // };
  const columns = [
    {
      name: "id",
      label: "City",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "generatedURL",
      label: "Link",
      options: {
        filter: false,
        display: true,
        customBodyRender: (value) => (
          <a
            href={value}
            onClick={(event) => {
              event.preventDefault();
              window.location.href = value;
            }}
          >
            {value}
          </a>
        ),
      },
    },
  ];
  const options = {
    filter: true,
    search: true,
    pagination: true,
    selectableRowsHideCheckboxes: true,
  };

  return (
    <div>
      <Grid
        item
        xs={6}
        sm={6}
        lg={4}
        xl={2}
        sx={{ maxWidth: "20%" }}
        style={pointerStyle}
      >
        <Paper
          onClick={handleSave}
          style={
            isHovered2 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
          }
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Box
                borderRadius="50%"
                width="30px"
                height="60px"
                style={{ display: "flex", marginLeft: "10px" }}
              ></Box>
            </div>
            <div>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "5px",
                }}
              ></Typography>
              <Typography style={{ color: "black" }}>
                <Box fontSize={12}>Add New Location</Box>
              </Typography>
            </div>
            <div />
          </div>
        </Paper>
      </Grid>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" align="left">
            Add New Location
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <IconButton
            style={{ position: "absolute", top: "0", right: "0" }}
            onClick={handleClose1}
          >
            <CloseIcon />
          </IconButton>

          <Grid container spacing={2} sx={{ mt: "20" }}>
            <Grid item xs={12}>
              <TextField
                label="City"
                value={vehicle}
                onChange={(e) => setVehicle(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            // onClick={handleSave}
            sx={{ m: 3, minWidth: 220 }}
            type="submit"
            ml={10}
            size="large"
            color="primary"
            mt={3}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Grid sx={{ mt: 5 }}>
        <MUIDataTable
          title="Add vehicle details"
          columns={columns}
          data={mapdata?.data}
          options={options}
        />
      </Grid>
    </div>
  );
};

export default Addcitymap;
