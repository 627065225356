import React, { useEffect, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import {
  collection,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";

const containerStyle = {
  height: "400px",
  width: "100%",
};

// const center = {
//   lat: 28.620585,
//   lng: 77.228609,
// };

const Geolocations = () => {
  const location = useLocation();
  // const {vehicle} = location.state ;
  // console.log("value", typeof vehicle );
  const [coordinates, setCoordinates] = useState([]);
  const [center, setCenter] = useState({ lat: 18.530139, lng: 73.857308 });
  const [searchTerm, setSearchTerm] = useState();
  const [generatedURL, setGeneratedURL] = useState("");

  useEffect(() => {
    // Check if the URL contains a valid map link
    const urlParams = new URLSearchParams(location.search);
    const linkCoordinates = urlParams.get("coords");
    if (linkCoordinates) {
      const parsedCoordinates = JSON.parse(linkCoordinates);
      setCoordinates(parsedCoordinates);
      // Find the center of the polygon to set the map center
      const centerLat =
        parsedCoordinates.reduce((sum, coord) => sum + coord.lat, 0) /
        parsedCoordinates.length;
      const centerLng =
        parsedCoordinates.reduce((sum, coord) => sum + coord.lng, 0) /
        parsedCoordinates.length;
      setCenter({ lat: centerLat, lng: centerLng });
    }
  }, [location.search]);

  const handlePolygonComplete = (polygon) => {
    const newCoordinates = polygon
      .getPath()
      .getArray()
      .map((coord) => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }));
    setCoordinates(newCoordinates);
    // Serialize the coordinates and save the link to the current location
    const serializedCoordinates = JSON.stringify(newCoordinates);
    const urlParams = new URLSearchParams({ coords: serializedCoordinates });
    const newUrl = `${location.pathname}?${urlParams.toString()}`;
    setGeneratedURL(newUrl);
    window.history.replaceState(null, "", newUrl);
  };

  const handleSearch = () => {
    if (searchTerm.trim() !== "") {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: searchTerm }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const { lat, lng } = results[0].geometry.location;
          setCenter({ lat: lat(), lng: lng() });

          // Create a new map instance with the updated center
          const map = new window.google.maps.Map(
            document.getElementById("map-canvas"),
            {
              center: { lat: lat(), lng: lng() },
              zoom: 12,
            }
          );

          const drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              clickable: true,
              draggable: false,
              editable: true,
              fillColor: "#ADFF2F",
              fillOpacity: 0.5,
            },
          });

          drawingManager.setMap(map);

          window.google.maps.event.addListener(
            drawingManager,
            "overlaycomplete",
            (event) => {
              if (
                event.type === window.google.maps.drawing.OverlayType.POLYGON
              ) {
                handlePolygonComplete(event.overlay);
              }
            }
          );
        } else {
          console.log(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    }
  };

  useEffect(() => {
    // Check if the Google Maps API has already been loaded
    if (!window.google) {
      // Load the Google Maps API script
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDB6W0oPY2YmJJMiT_ihxT1jJ360xT8Wlw&libraries=drawing`;
      googleMapsScript.async = true;
      googleMapsScript.defer = true;
      window.document.body.appendChild(googleMapsScript);

      googleMapsScript.addEventListener("load", () => {
        // Google Maps API loaded
        const map = new window.google.maps.Map(
          document.getElementById("map-canvas"),
          {
            center,
            zoom: 12,
          }
        );

        const drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            clickable: true,
            draggable: false,
            editable: true,
            fillColor: "#ADFF2F",
            fillOpacity: 0.5,
          },
        });

        drawingManager.setMap(map);

        window.google.maps.event.addListener(
          drawingManager,
          "overlaycomplete",
          (event) => {
            if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
              handlePolygonComplete(event.overlay);
            }
          }
        );
      });
    }
  }, [center]);

  useEffect(() => {
    console.log("Updated Coordinates (X, Y):", coordinates);
  }, [coordinates]);

  // const handleSave = () => {
  //   if (drawingManager) {
  //     const overlays = drawingManager.getOverlays();
  //     const polygonCoordinates = [];

  //     overlays.forEach((overlay) => {
  //       if (overlay instanceof window.google.maps.Polygon) {
  //         const polygon = overlay;
  //         const coordinates = polygon.getPath().getArray().map((coord) => ({
  //           lat: coord.lat(),
  //           lng: coord.lng(),
  //         }));
  //         polygonCoordinates.push(coordinates);
  //       }
  //     });

  //     // Store or process the polygonCoordinates as needed
  //     console.log('Saved Polygon Coordinates:', polygonCoordinates);
  //   }
  // };

  const handleGeneratedUrlClick = async () => {
    var loadMapWithPolygon = async () => {
      const urlParams = new URLSearchParams(location.search);
      const newUrl = `${location.pathname}?${urlParams.toString()}`;
      const linkCoordinates = urlParams.get("coords");
      if (linkCoordinates) {
        const parsedCoordinates = JSON.parse(linkCoordinates);
        setCoordinates(parsedCoordinates);

        // Find the center of the polygon to set the map center
        const centerLat =
          parsedCoordinates.reduce((sum, coord) => sum + coord.lat, 0) /
          parsedCoordinates.length;
        const centerLng =
          parsedCoordinates.reduce((sum, coord) => sum + coord.lng, 0) /
          parsedCoordinates.length;
        setCenter({ lat: centerLat, lng: centerLng });

        // Create a new map instance with the stored center
        const map = new window.google.maps.Map(
          document.getElementById("map-canvas"),
          {
            center: { lat: centerLat, lng: centerLng },
            zoom: 12,
          }
        );

        // Draw the polygon on the map
        const polygon = new window.google.maps.Polygon({
          paths: parsedCoordinates,
          clickable: true,
          draggable: false,
          editable: true,
          fillColor: "#ADFF2F",
          fillOpacity: 0.5,
        });

        // Add a listener to the polygon for changes
        polygon.addListener("mouseup", async () => {
          // Get the updated coordinates after editing the polygon
          const updatedCoordinates = polygon.getPath().getArray();

          // Find the center of the polygon to set the map center
          const centerLat =
            updatedCoordinates.reduce((sum, coord) => sum + coord.lat(), 0) /
            updatedCoordinates.length;
          const centerLng =
            updatedCoordinates.reduce((sum, coord) => sum + coord.lng(), 0) /
            updatedCoordinates.length;
          setCenter({ lat: centerLat, lng: centerLng });

          // Convert the updated coordinates to JSON string
          const plainCoordinates = updatedCoordinates.map((coord) => ({
            lat: coord.lat(),
            lng: coord.lng(),
          }));

          // Create a new URL with the updated coordinates
          const updatedUrlParams = new URLSearchParams(location.search);
          const coordsJSONString = JSON.stringify(plainCoordinates);
          updatedUrlParams.set("coords", coordsJSONString);
          const updatedUrl = `${
            location.pathname
          }?${updatedUrlParams.toString()}`;

          // Update the city document in Firebase with the new URL and coordinates
          const cityData = {
            generatedURL: updatedUrl,
            coordinates: plainCoordinates,
          };
          await setDoc(cityDocRef, cityData);
        });

        polygon.setMap(map);

        // Reverse geocode the coordinates to get the city name
        const geocoder = new window.google.maps.Geocoder();
        const geocodeResult = await new Promise((resolve, reject) => {
          geocoder.geocode(
            { location: { lat: centerLat, lng: centerLng } },
            (results, status) => {
              if (status === "OK" && results[0]) {
                resolve(results[0]);
              } else {
                reject("Reverse geocoding failed.");
              }
            }
          );
        });

        // Extract the city name from the geocode result
        const cityName = geocodeResult.address_components.find((component) =>
          component.types.includes("locality")
        ).long_name;

        // Create the document with the city name and coordinates
        const empCollectionRef = collection(db, "serviceable_places");
        const cityDocRef = doc(empCollectionRef, cityName);

        const cityData = {
          generatedURL: newUrl,
          coordinates: parsedCoordinates,
        };
        console.log(cityData, "++++=====");
        await setDoc(cityDocRef, cityData);
      }
    };

    await loadMapWithPolygon();
    window.open(generatedURL, "_self");
  };

  return (
    <div>
      <h2>Google Maps Draw Polygon Get Coordinates</h2>
      <div id="map-canvas" style={containerStyle}></div>
      <div>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search City or Location"
        />
        <button onClick={handleSearch}>Search</button>
        <button> Save </button>
      </div>
      <h4>Updated Coordinates (X, Y)</h4>
      <h4 style={{ cursor: "pointer" }} onClick={handleGeneratedUrlClick}>
        Generated URL:
      </h4>
      <div>{generatedURL}</div>
      <div
        id="info"
        style={{
          position: "absolute",
          color: "red",
          fontFamily: "Arial",
          height: "200px",
          fontSize: "12px",
        }}
      >
        {coordinates.map((coord, index) => (
          <div key={index}>
            {`Coordinate ${index + 1}: (${coord.lat.toFixed(
              6
            )}, ${coord.lng.toFixed(6)})`}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Geolocations;
