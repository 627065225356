import { configureStore } from "@reduxjs/toolkit";
import { SecondPost } from "../reducer/rider_reducer";
import FirstPost from "../reducer/reducer";
import ThirdPost from "../reducer/customer_reducer";
import FourthPost from "../reducer/map_reducer";
import FifthPost from "../reducer/bulk_reducer";
import VehiclePost from "../reducer/vehicle_reducer";
import DiscountPost from "../reducer/customer_discount_reducer";
import CustomerIssue from "../reducer/customer_issue_reducer";
import RiderIssue from "../reducer/rider_issue_reducer";

const store = configureStore({
  reducer: {
    post: FirstPost.reducer,
  },
  rider_reducer: {
    post: SecondPost.reducer,
  },
  customer_reducer: {
    post: ThirdPost.reducer,
  },
  map_reducer: {
    post: FourthPost.reducer,
  },
  bulk_reducer: {
    post: FifthPost.reducer,
  },
  vehicle_reducer: {
    post: VehiclePost.reducer,
  },
  customer_discount_reducer: {
    post: DiscountPost.reducer,
  },
  customer_issue_reducer: {
    post: CustomerIssue.reducer,
  },
  rider_issue_reducer: {
    post: RiderIssue.reducer,
  },
});
export default store;
