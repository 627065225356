import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  TextField,
  Grid,
  Box,
  Stack,
  Divider,
  Typography,
  Drawer,
  Paper,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomerNotification = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [customer, setCustomer] = useState("");
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [titleError, setTitleError] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [cityError, setCityError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [data, setData] = useState([]);

  const pointerStyle = {
    cursor: "pointer",
  };
  const paperStyle = {
    backgroundColor: "#1976D2", // Default color
  };

  const paperHoverStyle = {
    backgroundColor: "#38b6ff", // Hover color
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const validateInput = (inputValue, fieldName) => {
    if (inputValue === "") {
      switch (fieldName) {
        case "title":
          setTitleError("Please enter title");
          break;
        case "body":
          setBodyError("Please enter body");
          break;
        case "customer":
          setCustomerError("Please enter customer");
          break;
        case "city":
          setCityError("Please enter city");
          break;
        case "date":
          setDateError("Please enter date");
          break;
        case "time":
          setTimeError("Please enter time");
          break;

        default:
          break;
      }
      return false;
    }
    // Clear error when validation succeeds
    switch (fieldName) {
      case "title":
        setTitleError("");
        break;
      case "body":
        setBodyError("");
        break;
      case "customer":
        setCustomerError("");
        break;
      case "city":
        setCityError("");
        break;
      case "date":
        setDateError("");
        break;
      case "time":
        setTimeError("");
        break;

      default:
        break;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    const isTitleValid = validateInput(title, "title");
    const isBodyValid = validateInput(body, "body");
    const isCustomerValid = validateInput(customer, "customer");
    const isCityValid = validateInput(city, "city");
    const isDateValid = validateInput(date, "date");
    const isTimeValid = validateInput(time, "time");

    if (
      isTitleValid &&
      isBodyValid &&
      isCustomerValid & isCityValid &&
      isDateValid &&
      isTimeValid
    ) {
      console.log("Form submitted successfully");
      handleClose1();
      setTitle("");
      setBody("");
      setCustomer("");
      setCity("");
      setData("");
      setTime("");
      const payload = {
        title,
        body,
        customer,
        city,
        date,
        time,
      };

      console.log("Payload:", payload);
    } else {
      console.log("Form validation failed");
    }
  };
  const options = {
    filter: false, // Hide the filter bar
    search: true,
    pagination: true,
    selectableRowsHideCheckboxes: true,
    onRowClick: () => handleOpen(),
  };
  const handleOpen = () => {
    setOpenDrawer(true);
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const handleSubmitButtonClick = () => {
    setEditMode(false);
  };

  return (
    <div>
      <Grid
        item
        sx={{ maxWidth: "20%", marginTop: "30px" }}
        style={pointerStyle}
      >
        <Paper
          onClick={handleOpen1}
          style={
            isHovered2 ? { ...paperStyle, ...paperHoverStyle } : paperStyle
          }
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Box
                borderRadius="50%"
                width="30px"
                height="40px"
                style={{ display: "flex", marginLeft: "10px" }}
              ></Box>
            </div>
            <div>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: "black",
                  marginBottom: "0px",
                  marginTop: "5px",
                }}
              ></Typography>
              <Typography style={{ color: "black" }}>
                <Box fontSize={12}>Add Customer Notification</Box>
              </Typography>
            </div>
            <div />
          </div>
        </Paper>
      </Grid>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Typography variant="h6" align="left">
              Customer Notification
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}
              onClick={handleClose1}
            >
              <CloseIcon />
            </IconButton>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  label="title"
                  name="title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleError("");
                  }}
                  sx={{ marginTop: "30px", width: "267px" }}
                  variant="outlined"
                />
                {isSubmitted && titleError && (
                  <span style={{ color: "red" }}>{titleError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Body"
                  name="Body"
                  value={body}
                  onChange={(e) => {
                    setBody(e.target.value);
                    setBodyError("");
                  }}
                  sx={{ marginTop: "30px", width: "267px" }}
                  variant="outlined"
                />
                {isSubmitted && bodyError && (
                  <span style={{ color: "red" }}>{bodyError}</span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <FormControl
                  sx={{ mt: 3, minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Customer Type
                  </InputLabel>
                  <Select
                    value={customer}
                    onChange={(e) => {
                      setCustomer(e.target.value);
                      setCustomerError("");
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="customer Type"
                  >
                    <MenuItem value="VIP">VIP</MenuItem>
                    <MenuItem value="NON VIP">NON VIP</MenuItem>
                    <MenuItem value="ALL">ALL</MenuItem>
                  </Select>
                </FormControl>
                {isSubmitted && customerError && (
                  <span style={{ color: "red" }}>{customerError}</span>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  sx={{ mt: 3, minWidth: "100%" }}
                  variant="outlined"
                  m={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    City
                  </InputLabel>
                  <Select
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setCityError("");
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="city"
                  >
                    <MenuItem value="Delhi">Delhi</MenuItem>
                    <MenuItem value="Pune">Pune</MenuItem>
                    <MenuItem value="Mumbai">Mumbai</MenuItem>
                  </Select>
                </FormControl>
                {isSubmitted && cityError && (
                  <span style={{ color: "red" }}>{cityError}</span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: "20" }}>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  name="number"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                    setDateError("");
                  }}
                  sx={{ marginTop: "30px", minWidth: "100%" }}
                  variant="outlined"
                />
                {isSubmitted && dateError && (
                  <span style={{ color: "red" }}>{dateError}</span>
                )}
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label=""
                  name=""
                  type="time"
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value);
                    setTimeError("");
                  }}
                  sx={{ marginTop: "30px", minWidth: "120%" }}
                  variant="outlined"
                />
                {isSubmitted && timeError && (
                  <span style={{ color: "red" }}>{timeError}</span>
                )}
              </Grid>
            </Grid>
            <Button
              sx={{
                display: "flex",
                minWidth: 220,
                margin: "0 auto",
                marginTop: "5%",
              }}
              type="submit"
              ml={10}
              variant="contained"
              size="large"
              color="primary"
              mt={3}
              // onClick={handleSave}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Modal>
      <Grid sx={{ mt: 1 }}>
        <MUIDataTable
          title="Show user details"
          columns={columns}
          data={data}
          options={options}
        />
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        <div style={{ padding: 20, width: "985px" }}>
          <Typography variant="h4" style={{ marginBottom: 10 }}>
            Add vehicle all charges
          </Typography>

          <Box
            sx={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "16px",
              //   maxWidth: "800px",
              margin: "0 auto",
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <Typography variant="body1">Customer Notification</Typography>
            <>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!editMode}
                      label="title"
                      name="title"
                      variant="outlined"
                      sx={{ mt: 3, minWidth: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!editMode}
                      label="Body"
                      name="Body"
                      variant="outlined"
                      sx={{ mt: 3, minWidth: "100%" }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      sx={{ mt: 3, minWidth: "100%" }}
                      variant="outlined"
                      m={2}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Customer Type
                      </InputLabel>
                      <Select
                        disabled={!editMode}
                        value={customer}
                        onChange={(e) => setCustomer(e.target.value)}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="customer Type"
                      >
                        <MenuItem value="VIP">VIP</MenuItem>
                        <MenuItem value="NON VIP">NON VIP</MenuItem>
                        <MenuItem value="ALL">ALL</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      sx={{ mt: 3, minWidth: "100%" }}
                      variant="outlined"
                      m={2}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        City
                      </InputLabel>
                      <Select
                        disabled={!editMode}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="city"
                      >
                        <MenuItem value="Delhi">Delhi</MenuItem>
                        <MenuItem value="Pune">Pune</MenuItem>
                        <MenuItem value="Mumbai">Mumbai</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      disabled={!editMode}
                      type="date"
                      name="number"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      sx={{ marginTop: "30px", minWidth: "100%" }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      disabled={!editMode}
                      label=""
                      name=""
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      sx={{ marginTop: "30px", minWidth: "120%" }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          </Box>
        </div>
        {editMode ? (
          <Button
            sx={{ m: 3, maxWidth: "70px" }}
            type="submit"
            variant="contained"
            color="primary"
            mt={3}
            onClick={handleSubmitButtonClick}
          >
            Submit
          </Button>
        ) : (
          <Button
            sx={{ m: 3, maxWidth: "70px" }}
            type="submit"
            variant="contained"
            color="primary"
            mt={3}
            onClick={handleEditButtonClick}
          >
            Edit
          </Button>
        )}
      </Drawer>
    </div>
  );
};
const columns = [
  {
    name: "Title",
    label: "Title",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "body",
    label: "body",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Customer type",
    label: "Customer type",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "city",
    label: "city",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "date",
    label: "date",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Time",
    label: "Time",
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: "Action",
    label: "Action",
    options: {
      filter: false,
      display: true,
      sort: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <>
            <Stack direction="row" spacing={1}>
              {/* <Link to="/editorder"> */}
              <Button variant="contained" size="small" color="primary">
                <EditIcon fontSize="small" />
              </Button>
              {/* </Link> */}
              <Button
                m={1}
                variant="contained"
                size="small"
                style={{ backgroundColor: "red" }}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Stack>
          </>
        );
      },
    },
  },
];
export default CustomerNotification;
