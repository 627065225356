import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Card,
  Chip,
  Drawer,
  Stack,
  TextField,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { getPostData } from "../redux/action/customer_action";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material"; // Import CircularProgress for the loader

const Customerdetails = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.post);
  // const loading = useSelector((state) => state.customer_reducer.post.loading);
  const [ddata, setDdata] = useState();
  const [formattedData, setFormattedData] = useState();
  const [customerData, setCustomerData] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    dispatch(getPostData());
  }, []);

  // console.log("customer data  data", userdata);
  useEffect(() => {
    if (userdata.data.length) {
      setCustomerData(userdata);
    } else {
      console.log("Data is not available");
    }
  }, [userdata]); // Add 'userdata' as a dependency

  // console.log("CustomerData data:", customerData);

  // console.log(customerData?.data[1]?.customerId, "345666");

  const handleOpen = (item, row) => {
    // console.log(row.length, "11111111111!!!vgvgvgh");

    for (let i = 0; i < customerData.data.length; i++) {
      if (item[0] === customerData.data[i].customerId) {
        return setFormattedData([customerData.data[i]]), setOpenDrawer(true);
      }
    }
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };

  function formatTime(sec) {
    const date = new Date(sec * 1000);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${date.toLocaleDateString()} :: ${hours}:${minutes}:${seconds}`;
  }
  const CustomBodyRenderLite = ({ tableMeta }) => {
    const handleRowClick = () => {
      const rowData = tableMeta.rowData;
      return rowData;
    };
    return (
      <>
        <Stack direction="row" spacing={1}>
          <Link
            to="/editcustomer"
            onClick={handleRowClick}
            state={{ from: handleRowClick() }}
          >
            <Button variant="contained" size="small" color="primary">
              <EditIcon fontSize="small" />
            </Button>
          </Link>
          {/* Additional buttons and components */}
        </Stack>
      </>
    );
  };

  const columns = [
    {
      name: "customerId",
      label: "Customer id",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "address",
      label: "address",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "userName",
      label: "username",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "email",
      label: "email",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "mobileNumber",
      label: "Phone number",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "appliedReferCode",
      label: "appliedReferCode",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "totalCreditedReferalAmount",
      label: "totalCreditedReferalAmount",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "appliedReferCode",
      label: "Refer codes",
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: "accountStatus",
      label: " Account status",
      options: {
        filter: false,
        display: true,
      },
    },

    {
      name: "customerType ",
      label: " customerType",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "deviceList",
      label: " deviceList",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "referCode ",
      label: "referCode",
      options: {
        filter: false,
        display: false,
      },
    },

    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (rowData, tableMeta, updateValue) =>
          CustomBodyRenderLite({ rowData, tableMeta, updateValue }),
      },
    },
  ];
  const options = {
    filter: false, // Hide the filter bar
    selectableRows: "multiple",
    searchOpen: true,
    search: false,
    selectableRowsHideCheckboxes: true,
    onRowClick: (rowData, row) => handleOpen(rowData, row),

    customToolbar: () => {
      return (
        <div style={{ padding: "15px" }}>
          {" "}
          <h2
            style={{
              position: "absolute",
              top: "30px",
              left: "10px",
              zIndex: 999,
            }}
          >
            Customer details
          </h2>
        </div>
      );
    },

    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div
          style={{
            position: "absolute",
            marginTop: "15px", // Adjust this value to control the gap
            right: "10px",
            zIndex: 999,
          }}
        >
          <input
            style={{
              borderRadius: "4px",
              height: "22px",
              border: "1px solid",
              borderColor: "#38b6ff",
              marginRight: "10px",
              padding: "5px",
            }}
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </div>
      );
    },
  };
  return (
    <Grid>
      <Grid
        style={{
          marginTop: "0px",
          height: "100%",
          padding: "10px",
        }}
      >
        {/* {loading ? ( // Conditionally render loader if loading is true
          <CircularProgress />
        ) : ( */}
        <MUIDataTable
          data={ddata ? ddata : customerData?.data}
          columns={columns}
          options={options}
        />
        {/* )} */}
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        {formattedData?.map((item, index) => {
          return (
            <div style={{ padding: 20, width: "985px" }} key={index}>
              <Typography variant="h5" style={{ marginBottom: 10 }}>
                Customer Details
              </Typography>
              <Typography variant="h5" style={{ marginBottom: 2 }}>
                CUSTOMER ID : {item.customerId}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                  gap: "10px",
                }}
              >
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#c5e7b5",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Account status</Typography>
                  <br />
                  {item.accountStatus}
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Wallet ID</Typography>
                  <br />
                  {item.walletId}
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Created Date</Typography>
                  <br />
                  <div>{formatTime(item.createdAt)}</div>
                </box>
                <box
                  style={{
                    padding: 20,
                    marginTop: 10,
                    flex: 1,
                    backgroundColor: "#bad6f2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h6">Updated Date</Typography>
                  <br />
                  <div>{formatTime(item.lastUpdatedAt)}</div>
                </box>
              </div>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ############################## customer detaila ######################## */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <box style={{ padding: 15, marginTop: 10, flex: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <img
                      style={{
                        borderRadius: "50%",
                        width: "100px",
                        height: "100px",
                      }}
                      src={
                        item.profilePic ||
                        "https://www.w3schools.com/w3images/avatar6.png"
                      }
                      alt="Profile"
                    />
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.userName}
                  </Typography>{" "}
                  <br />
                  <Typography
                    sx={{
                      justifyContent: "center",
                      marginBottom: 2,
                      marginLeft: 10,
                    }}
                  >
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Phone_No:</strong>
                            {item.mobileNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Email:</strong> {item.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Refer code:</strong>
                            {item.referCode}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Oredr Cancle Count:</strong>{" "}
                            {item.orderCancelCount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Applied Referal code:</strong>
                            {item.appliedReferCode}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Reference Amount:</strong>{" "}
                            {item.ReferenceAmount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Customer Type:</strong>
                            {item.customerType}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>City:</strong>
                            {item.city}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>

                    <Typography sx={{ display: "flex", marginLeft: 10 }}>
                      <Grid container spacing={2}>
                        {/* <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>walletId:</strong>
                            {item.walletId}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Rating:</strong>
                            {item.rating}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Typography>
                </box>
              </div>
              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ##############################  End customer detaila ######################## */}
              {/* ################################ savedAddresses ######################### */}
              <div>
                {item.savedAddresses.map((data, index) => (
                  <div style={{ padding: 20, marginTop: 10 }} key={index}>
                    <Typography variant="h6">Saved Address</Typography>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 2,
                        marginLeft: 12,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            <strong>additionalInfo:</strong>{" "}
                            {data.additionalInfo}
                          </Typography>
                          <Typography variant="body1">
                            <strong>contactNumber:</strong> {data.contactNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            <strong>address:</strong> {data.address}
                          </Typography>
                          <Typography variant="body1">
                            <strong>name:</strong> {data.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                ))}
              </div>

              <Divider sx={{ height: "10px", fontWeight: "bold" }} />
              {/* ################################ End  savedAddresses ######################### */}
            </div>
          );
        })}
      </Drawer>
    </Grid>
  );
};

export default Customerdetails;
